<template>
  <div class="row">
    <modal :adaptive="true"
           classes="border-15px primary-border-2px px-5 py-3"
           height="auto"
           name="invoice-confirm-modal"
           width="75%"
    >
      <div class="d-flex justify-content-center">
        <span v-if="didClickCancel === false" v-html="getIconByKey('icons.invoice.question', {
          class: 'w-100px h-100px d-inline-block object-cover'
        })"></span>
        <span v-if="didClickCancel === true" v-html="getIconByKey('icons.invoice.edit', {
          class: 'w-100px h-100px d-inline-block object-cover'
        })">
        </span>
      </div>
      <div class="d-flex justify-content-center">
        <span v-if="didClickCancel === false" class="d-inline-block text-2xl text-center w-75">
          {{ $t('invoice.confirm_modal_message') }}
        </span>
        <span v-if="didClickCancel === true" class="d-inline-block text-2xl text-center w-75">
          {{ $t('invoice.edit_confirm_modal_message') }}
        </span>
      </div>
      <div class="row mx-0 py-5">
        <div class="col-12 row mx-0 py-2 navy-blue-border-bottom-1px text-xl font-weight-bold">
          <div class="col-3 text-center">{{ $t('invoice.goods_or_service') }}</div>
          <div class="col-3 text-center">{{ $t('invoice.quantity') }}</div>
          <div class="col-3 text-center">{{ $t('invoice.unit_price') }}</div>
          <div class="col-3 text-center">{{ $t('invoice.total') }}</div>
        </div>
        <div class="col-12 row mx-0 py-2 text-xl">

          <template v-for="item in transactions">
            <div class="col-3 text-center">{{ item.line_code }}</div>
            <div class="col-3 text-center">
              <number-input
                  :disabled="didClickCancel === false"
                  :model.sync="item.amount"
                  :required="false"
              ></number-input>
            </div>
            <div class="col-3 text-center">
              <number-input
                  :disabled="didClickCancel === false"
                  :model.sync="item.unit_price"
                  :required="false"
              ></number-input>
            </div>
            <div class="col-3 text-center">{{ item.amount * item.unit_price }} {{ selectedCurrency }}</div>

          </template>

        </div>
      </div>

      <div class="row mx-0">
        <div class="col-lg-6">
          <div v-if="didClickCancel === false" class="col-lg-12 mt-2">
            <button-with-icon
                :text="$t('invoice.yes_it_is_right_and_save_invoice')"
                btn-classes="primary-border-2px"
                class="font-weight-boldest text-primary"
                icon-name="icons.invoice.yes"
                size="lg"
                @onClick="submitInvoice"
            ></button-with-icon>
          </div>
          <div v-if="didClickCancel === false" class="col-lg-12 mt-2">
            <button-with-icon
                :text="$t('invoice.no_it_is_wrong_and_edit')"
                btn-classes="danger-border-2px"
                class="font-weight-boldest text-danger"
                icon-name="icons.invoice.no"
                size="lg"
                @onClick="didClickCancel = true"
            ></button-with-icon>
          </div>
          <div v-if="didClickCancel === true" class="col-lg-12 mt-2">
            <button-with-icon
                :text="$t('invoice.save_invoice_like_this')"
                btn-classes="danger-border-2px"
                class="font-weight-boldest text-danger"
                icon-name="icons.invoice.yes"
                size="lg"
                @onClick="didClickSaved = true"
            ></button-with-icon>
          </div>
          <div v-if="didClickSaved === true" class="col-lg-12 mt-2">
            <custom-multi-select
                :is-inline="false"
                :item-per-row="1"
                :max="1"
                :model.sync="more_amount_id"
                :not-list="true"
                :options="moreAmountOptions"
                :required="true"
                :title="$t('invoice.what_you_need_to_do_for_more_amount')"
                name="invoice_"
            ></custom-multi-select>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="lg-w-50 md-w-100 py-3 px-5 border-15px navy-blue-border-1px bg-white">
            <invoice-layout
                :buying-type-options="options.receiveTypeOptions"
                :buying_type_id.sync="form.invoice_receiving_type_id"
                :currency-options="options.currencyOptions"
                :currency-rates="currencyRates"
                :currency_unit_id.sync="form.currency_unit_id"
                :discount.sync="form.total_discount"
                :exchange.sync="form.exchange_rate"
                :item="form"
                :show-extra="Number(form.is_withholding)"
                :transactions="transactions"
                :vat-options="vatRateOptions"
                :vat-rates="vat_rates"
            ></invoice-layout>
          </div>
        </div>
      </div>
      <div v-if="selecteMoreOption === true" class="d-flex justify-content-center mt-2 ">
        <button-with-icon
            :text="$t('invoice.send')"
            btn-classes="danger-border-2px"
            class="font-weight-boldest text-danger"
            size="lg"
            @onClick="submitInvoice"
        ></button-with-icon>
      </div>
    </modal>

    <div id="filter-card" class="row justify-content-start items-baseline w-100 mb-2" style="margin-bottom: -5px;">
        <span class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2" @click="$router.go(-1)">
          <i class="fa fa-chevron-left"></i>
        </span>
      <h3 id="page-title" class="d-inline-block">{{ $t('invoice.accepted_invoice') }}</h3>
    </div>

    <div class="col-md-12">
      <dashboard-box>
        <template v-slot:preview>
          <div class="px-5 py-3">
            <div class="row items-start">
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 row mx-0 font-weight-boldest">
                <div class="col-12 items-start">
                  <date-picker-input
                      :disabled="true"
                      :model="today"
                      :required="false"
                      :title="$t('invoice.date')"
                  ></date-picker-input>
                </div>

                <div class="col-12">
                  <custom-multi-select
                      :item-per-row="1"
                      :max="1"
                      :model.sync="form.supplier_company_id"
                      :not-list="true"
                      :options="options.companyOptions"
                      :required="true"
                      :title="$t('invoice.company_name')"
                      name="invoice_company_name"
                  ></custom-multi-select>
                </div>
                <div class="col-12">
                  <custom-multi-select
                      :disabled="!form.supplier_company_id"
                      :is-inline="false"
                      :model.sync="form.waybill_ids"
                      :not-list="false"
                      :options="options.waybills"
                      :required="false"
                      :title="`İrsaliye`"
                      class="mr-3"
                      name="invoice_waybill"
                      :show-invalid="isValidWaybillSelect"
                  ></custom-multi-select>
                </div>

                <div class="col-12 d-lg-none d-md-flex mb-3">
                  <div class="w-200px">
                    <span class="text-lg">
                      {{ $t('invoice.add_withholding') }}
                    </span>
                  </div>
                  <div class="flex-grow-1">
                    <b-form-checkbox
                        id="invoice_withholding"
                        v-model="form.is_withholding"
                        class="checkbox"
                        name="invoice_withholding"
                        unchecked-value="0"
                        value="1"
                    ></b-form-checkbox>
                  </div>
                </div>
                <div class="col-12 row mx-0 px-1">
                  <div class="col-6 px-1">
                    <button-with-icon
                        :active="true"
                        :disabled="form.invoice_category_id !== 1"
                        :text="$t('invoice.scan_qr_code')"
                        icon-name="icons.invoice.by_scanning_qr"
                        size="sm"
                        @onClick="form.invoice_category_id = 1"
                    ></button-with-icon>
                  </div>
                  <div class="col-6 px-1">
                    <button-with-icon
                        :active="true"
                        :disabled="form.invoice_category_id !== 2"
                        :text="$t('invoice.enter_manually')"
                        icon-name="icons.invoice.manually_entry"
                        size="sm"
                        @onClick="form.invoice_category_id = 2"
                    ></button-with-icon>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 row mx-0 font-weight-boldest">
                <div class="col-12">
                  <text-input
                      :model.sync="form.invoice_no"
                      :placeholder="$t('invoice.please_enter_invoice_number')"
                      :required="true"
                      :title="$t('invoice.invoice_number')"
                  ></text-input>
                </div>
                <div class="col-12">
                  <date-picker-input
                      :model.sync="form.invoice_date"
                      :required="true"
                      :title="$t('invoice.invoice_date')"
                  ></date-picker-input>
                </div>
                <div class="col-12 d-lg-none d-md-block" v-if="false">
                  <custom-multi-select
                      :max="1"
                      :model.sync="form.vat_rate_id"
                      :not-list="true"
                      :options="vatRateOptions"
                      :title="$t('invoice.vat')"
                      name="vat_rate_id"
                  ></custom-multi-select>
                </div>

                <div class="col-12 d-flex justify-content-start">
                  <custom-multi-select
                      :is-inline="false"
                      :max="1"
                      :model.sync="form.invoice_type_id"
                      :not-list="true"
                      :options="options.invoiceTypeOptions"
                      :required="true"
                      :title="$t('invoice.invoice_type')"
                      class="mr-3"
                      name="invoice_type"
                  ></custom-multi-select>
                  <custom-multi-select
                      :disabled="!form.invoice_type_id"
                      :is-inline="false"
                      :max="1"
                      :model.sync="form.invoice_category_id"
                      :not-list="true"
                      :options="options.invoiceCategoryOptions"
                      :required="true"
                      :title="`Tipi`"
                      class="mr-3"
                      name="invoice_kind"
                  ></custom-multi-select>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 row mx-0 font-weight-boldest">
                <div class="col-12 pt-md-3">
                  <file-input
                      ref="invoice_file"
                      :model.sync="form.image"
                      :required="true"
                      :title="null"
                      accept="image/*"
                      name="invoice_file"
                      @fileChange="onInvoiceUpload">
                    <template v-slot:inputDesign>
                      <button-with-icon
                          :icon-name="null"
                          :text="$t('invoice.upload_invoice')"
                          size="md"
                          @onClick="$refs.invoice_file.openFileInput()"
                      ></button-with-icon>
                    </template>
                  </file-input>
                </div>

               <div class="col-12 mb-3 d-lg-flex d-md-none">
                  <div class="w-200px">
                    <span class="text-lg">
                      {{ $t('invoice.overhead_invoice') }}
                    </span>
                  </div>
                  <div class="flex-grow-1">
                    <b-form-checkbox
                        id="invoice_overhead_invoice"
                        v-model="form.overhead_invoice"
                        class="checkbox"
                        name="invoice_overhead_invoice"
                        unchecked-value="0"
                        value="1"
                    ></b-form-checkbox>
                  </div>
                </div>
                <div class="col-12 mb-3 d-lg-flex d-md-none">
                  <div class="w-200px">
                    <span class="text-lg">
                      {{ $t('invoice.replace_the_waybill') }}
                    </span>
                  </div>
                  <div class="flex-grow-1">
                    <b-form-checkbox
                        id="invoice_replace_the_waybill"
                        v-model="form.replace_the_waybill"
                        class="checkbox"
                        name="invoice_replace_the_waybill"
                        unchecked-value="0"
                        value="1"
                    ></b-form-checkbox>
                  </div>
                </div>

                <div class="col-12 mb-3 d-lg-flex d-md-none">
                  <div class="w-200px">
                    <span class="text-lg">
                      {{ $t('invoice.add_withholding') }}
                    </span>
                  </div>
                  <div class="flex-grow-1">
                    <b-form-checkbox
                        id="invoice_withholding"
                        v-model="form.is_withholding"
                        class="checkbox"
                        name="invoice_withholding"
                        unchecked-value="0"
                        value="1"
                    ></b-form-checkbox>
                  </div>
                </div>
                <div v-if="+form.is_withholding === 1" class="col-12 d-lg-block d-md-none">
                  <custom-multi-select
                      :max="1"
                      :required="false"
                      :model.sync="form.vat_deduct_id"
                      :not-list="true"
                      :options="options.vatDeducts"
                      :title="$t('invoice.vatDeduct')"
                      name="vat_deduct_id"
                      :show-invalid="false"
                  ></custom-multi-select>
                </div>
                <div v-if="showVatExceptionField" class="col-12 d-lg-block d-md-none">
                  <custom-multi-select
                      :max="1"
                      :required="false"
                      :model.sync="form.vat_exception_id"
                      :not-list="true"
                      :options="options.vatExceptions"
                      :title="$t('invoice.vatException')"
                      name="vat_exception_id"
                  ></custom-multi-select>
                </div>
                <div v-if="+form.is_withholding === 1" class="col-12 d-lg-block d-md-none">
                  <text-input
                      :helper-text="$t('general.please_enter_manually')"
                      :is-inline="true"
                      :model.sync="form.withholding_share"
                      :placeholder="''"
                      :title="$t('invoice.withholding_share')"
                      class="font-weight-bolder"
                      input-width="125px"
                      name="withholding_share"
                  ></text-input>
                  <text-input
                      :helper-text="$t('general.please_enter_manually')"
                      :is-inline="true"
                      :model.sync="form.withholding_denominator"
                      :placeholder="''"
                      :title="$t('invoice.withholding_denominator')"
                      class="font-weight-bolder"
                      input-width="125px"
                      name="withholding_denominator"
                  ></text-input>
                </div>
                <div class="col-12 d-lg-block d-md-none" v-if="false">
                  <custom-multi-select
                      :max="1"
                      :model.sync="form.vat_rate_id"
                      :not-list="true"
                      :options="vatRateOptions"
                      :title="$t('invoice.vat')"
                      name="vat_rate_id"
                  ></custom-multi-select>
                </div>
                <div v-show="form.image !== null" class="col-12 py-2 text-right">
                  <img id="invoice_image" class="w-75px h-75px object-cover primary-border-1px border-15px" src="">
                </div>

                <div v-if="isValidToSubmit" class="col-12 d-flex justify-content-end my-3">
                  <button-with-icon
                      :icon-name="null"
                      :text="$t('invoice.save_invoice')"
                      class="w-200px text-primary"
                      size="lg"
                      @onClick="openPopUpModal"
                  ></button-with-icon>
                </div>
              </div>
            </div>
            <div class="relative">
              <div v-show="isValidToContinue" class="col-12 p-0 m-0 text-primary h-20px">
              <span class="float-right" @click="addNewRow" v-html="getIconByKey('icons.waybill.waybill_entry', {
                  class: 'w-35px h-35px d-inline-block object-fill cursor-pointer'
                })">
             </span>
              </div>
              <div v-show="isValidToContinue" class="w-100 col-12 border-15px navy-blue-border-1px mx-3 mt-5 mb-1">
                <table class="table w-100 border-15px mb-0">
                  <thead class="w-100">
                  <tr>
                    <th v-if="!showProductSelectField" class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                      <div class="text-right text-lg pr-10">
                        {{ $t('invoice.instruction_type') }}
                      </div>
                    </th>

                    <th v-if="!showProductSelectField" class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                      <div class="text-right text-lg pr-10">
                        {{ $t('invoice.order_number') }}
                      </div>
                    </th>

                    <th v-if="!showProductSelectField" class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                      <div class="text-right text-lg pr-10">
                        {{ $t('invoice.product') }}
                      </div>
                    </th>

                    <th v-if="showProductSelectField" class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                      <div class="text-right text-lg pr-10">
                        {{ $t('invoice.code') }}
                      </div>
                    </th>
                    <th v-if="showProductSelectField" class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                      <div class="d-flex justify-content-center items-center text-lg">
                        {{ $t('invoice.code_name') }}
                        <info-tooltip
                            :helper-text="$t('invoice.please_enter_manually')"
                        ></info-tooltip>
                      </div>
                    </th>
                    <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                      <div class="d-flex justify-content-center items-center text-lg">
                        {{ $t('invoice.amount') }}
                        <info-tooltip
                            :helper-text="$t('invoice.please_enter_manually')"
                        ></info-tooltip>
                      </div>
                    </th>
                    <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                      <div class="d-flex justify-content-center items-center text-lg">
                        {{ $t('invoice.unit') }}
                        <info-tooltip
                            :helper-text="$t('invoice.please_enter_manually')"
                        ></info-tooltip>
                      </div>
                    </th>
                    <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                      <div class="d-flex justify-content-center items-center text-lg">
                        {{ $t('invoice.unit_price') }}
                        <info-tooltip
                            :helper-text="$t('invoice.please_enter_manually')"
                        ></info-tooltip>
                      </div>
                    </th>
                    <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                      <div class="d-flex justify-content-center items-center text-lg">
                        {{ $t('invoice.vat_amount') }}
                        <info-tooltip
                            :helper-text="$t('invoice.please_enter_manually')"
                        ></info-tooltip>
                      </div>
                    </th>
                    <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                      <div class="d-flex justify-content-center items-center text-lg">
                        {{ $t('invoice.index_amount') }}
                        <info-tooltip
                            :helper-text="$t('invoice.please_enter_manually')"
                        ></info-tooltip>
                      </div>
                    </th>
                    <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                      <div class="d-flex justify-content-center items-center text-lg">
                        {{ $t('invoice.amount_total') }}
                        <info-tooltip
                            :helper-text="$t('invoice.please_enter_manually')"
                        ></info-tooltip>
                      </div>
                    </th>

                    <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                      <div class="d-flex justify-content-center items-center text-lg">
                        {{ $t('order.description') }}
                      </div>
                    </th>
                    <th class="navy-blue-border-bottom-1px">
                      <div class="d-flex justify-content-center items-center text-lg">
                        #
                      </div>
                    </th>
                  </tr>
                  </thead>
                  <tbody class="navy-blue-bottom-1px">
                  <template v-for="(item, key) in transactions">
                    <tr>
                      <td v-if="!showProductSelectField" class="navy-blue-border-right-1px">
                        <custom-multi-select
                            :is-inline="false"
                            :max="1"
                            :model.sync="item.instruction_type_id"
                            :not-list="true"
                            :options="options.instructionTypeOptions"
                            :required="false"
                            name="instruction_type"
                            title=""
                            @save="instructionTypeSave($event, key)"
                        ></custom-multi-select>
                      </td>
                      <td v-if="!showProductSelectField" class="navy-blue-border-right-1px">
                        <custom-multi-select
                            :has-bottom-margin="false"
                            :is-inline="false"
                            :model.sync="item.waybillable_ids"
                            :options="item.orderOptions"
                            :required="false"
                            input-width="150px"
                            max="1"
                            name="waybillable_ids"
                            object-key="name"
                            title=""
                            :show-invalid="isValidSelectOverheadDisableWaybilleId"
                            @save="waybillableIdsChanged($event, key)"
                        />
                      </td>

                      <td v-if="!showProductSelectField" class="navy-blue-border-right-1px">
                        <div v-if="item.waybillable && item.waybillable.product_name"
                             class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                          <custom-multi-select
                              :is-inline="false"
                              :model.sync="item.order_product_id"
                              :options="item.waybillable.detail"
                              :placeholder="$t('waybill.please_enter_product_color')"
                              :required="false"
                              max="1"
                              name="wayBillOrderColor"
                              not-list="true"
                              object-key="color"
                              title=""
                              @save="onSelectProduct($event, key)"
                          >
                          </custom-multi-select>
                        </div>
                      </td>

                      <td v-if="showProductSelectField" class="navy-blue-border-right-1px">
                        <input v-model="item.line_code" class="form-control d-inline-block mb-5" type="text">
                      </td>

                      <td v-if="showProductSelectField" class="navy-blue-border-right-1px items-start">
                        <input v-model="item.line_code_name" class="form-control d-inline-block mb-5" type="text">
                      </td>

                      <td class="navy-blue-border-right-1px items-start">
                        <input v-model="item.amount" class="form-control d-inline-block mb-5" type="number">
                      </td>

                      <td class="navy-blue-border-right-1px items-start">
                        <custom-multi-select
                            :input-width="`65px`"
                            :is-inline="false"
                            :item-per-row="1"
                            :max="1"
                            :model.sync="item.amount_unit_id"
                            :not-list="true"
                            :options="unitOptions[key]"
                            :required="false"
                            :title="null"
                            name=""
                        ></custom-multi-select>
                      </td>
                      <td class="navy-blue-border-right-1px">
                        <input v-model="item.unit_price" class="form-control form-control-sm d-inline-block" step="1"
                               type="number">
                      </td>
                      <td class="navy-blue-border-right-1px text-center">
                        <custom-multi-select
                            :max="1"
                            :model.sync="item.vat_rate_id"
                            :not-list="true"
                            :options="vatRateOptions"
                            :required="false"
                            name="transaction_vat_rate_id"
                            title=""
                        ></custom-multi-select>

                        <span class="font-weight-boldest"> {{ item.vat_price | priceFormat }} </span>
                      </td>
                      <td class="navy-blue-border-right-1px">
                        {{ item.price | priceFormat }} {{ selectedCurrency }}
                      </td>
                      <td class="navy-blue-border-right-1px">
                        {{ item.total_price | priceFormat }} {{ selectedCurrency }}
                      </td>
                      <td class="navy-blue-border-right-1px">
                        <input v-model="item.description" class="form-control form-control-sm" type="text">

                        <span></span>
                      </td>
                      <td class="">
                        <span class="float-right" @click="deleteTranscationsRow(key)" v-html="getIconByKey('icons.sample.delete_red', {
                              class: 'w-25px h-25px d-inline-block object-fill cursor-pointer'
                            })">
                        </span>
                      </td>
                    </tr>
                  </template>

                  </tbody>
                </table>
              </div>

              <div v-show="currentHoveredItem" id="detail-pop-up" class="fixed w-175px z-index-9999"
                   style="top: 50%; right: 50%;">
                <div class="w-20px h-20px float-right navy-blue-border-1px z-index-3000"
                     style="transform: rotate(45deg); top: -10px; left: 50%; margin-left: -10px"></div>
                <div
                    class="row font-weight-boldest relative p-2 border-10px bg-white navy-blue-border-1px z-index-9999">
                  <div class="col-6">{{ $t('invoice.quantity') }}</div>
                  <div class="col-6">: 1500</div>

                  <div class="col-6">{{ $t('invoice.price') }}</div>
                  <div class="col-6">: 80 E</div>

                  <div class="col-6">{{ $t('invoice.vat') }}</div>
                  <div class="col-6">: %4</div>

                  <div class="col-6">{{ $t('invoice.total') }}</div>
                  <div class="col-6">: 7000</div>
                </div>
              </div>

            </div>
          </div>
          <div v-if="isValidToShowPopUp && isValidToContinue"
               class="float-right mb-n20 w-50 lg:w-35 md:w-50 sm:w-50 py-3 px-5 border-15px navy-blue-border-1px bg-white"
               style="bottom: 0; right: 0;">
            <invoice-layout
                :buying-type-options="options.receiveTypeOptions"
                :buying_type_id.sync="form.invoice_receiving_type_id"
                :currency-options="options.currencyOptions"
                :currency-rates="currencyRates"
                :currency_unit_id.sync="form.currency_unit_id"
                :discount.sync="form.total_discount"
                :exchange.sync="form.exchange_rate"
                :item="form"
                :transactions="transactions"
                :vat-options="vatRateOptions"
                :vat-rates="vat_rates"
            ></invoice-layout>
          </div>
        </template>

      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters, mapMutations} from 'vuex'
import moment from "moment";

import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import NumberInput from "@/assets/components/inputs/NumberInput";
import TextInput from "@/assets/components/inputs/TextInput";
import FileInput from "@/assets/components/inputs/FileInput";
import DataTable from "@/assets/components/dataTable/DataTable";
import InfoTooltip from "@/assets/components/widget/InfoTooltip";

import {CREATE_ITEM, GET_ITEM_DETAIL_BY_ID, GET_ITEMS, LOADING, RESET_VALUES} from "@/core/services/store/REST.module";
import {LARAVEL_DATE_FORMAT} from "@/core/config/constant";
import SelectInput from "@/assets/components/inputs/SelectInput";
import InvoiceLayout from "@/view/pages/invoice/layouts/InvoiceLayout";
import format from "@popperjs/core/lib/utils/format";

export default {
  name: "InvoiceCreateEdit",
  components: {
    InvoiceLayout,
    SelectInput,
    FileInput,
    NumberInput,
    ButtonWithIcon,
    DatePickerInput,
    CustomMultiSelect,
    DashboardBox,
    DataTable,
    InfoTooltip,
    TextInput
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
    }),
    showVatExceptionField() {
      if (this.form.vat_rate_id) {
        let data = this.options.vatOptions[this.form.vat_rate_id];
        if (+data === 0) {
          return true;
        }
      }
      return false;
    },
    showProductSelectField() {
      return this.form.waybill_ids && this.form.waybill_ids.length;
    },
    vatRateOptions() {
      let options = this.options.vatOptionsRaw || {};
      let data = options.filter(item => +item.type_id === 4)
      return this.convertArrayToObjectByKey(data, 'id', 'vat.rate');
    },
    selectedCurrency() {
      if (this.form.currency_unit_id) {
        return this.options.currencyOptions[this.form.currency_unit_id]
      }
      return "";
    },
    transactionVatPrice() {
      return function (key) {
        let self = this;
        return self.transactionPrice(key) * self.options.vatOptions[self.transactions[key].vat_rate_id] / 100;
      }
    },
    transactionPrice() {
      return function (key) {
        let self = this;
        return self.transactions[key].amount * self.transactions[key].unit_price;
      }
    },
    transactionTotalPrice() {
      return function (key) {
        return this.transactionPrice(key) + this.transactionVatPrice(key);
      }
    },
    vat_rates() {
      let self = this;
      return _.map(_.groupBy(this.transactions, 'vat_rate_id'), (o, idx) => {
        let vatRate = self.options.vatOptions[idx]
        return {
          rate: vatRate, sum: _.sumBy(o, 'vat_price')
        }
      })
    },
    calculated_vat() {
      let sum = 0;
      let self = this;
      this.transactions.forEach(item => {
        sum += item.amount * item.unit_price * self.options.vatOptions[item.vat_rate_id] / 100;
      })
      return sum;
    },
    total_amount() {
      let sum;
      return sum = this.total + this.calculated_vat - this.form.total_discount;
    },
    total() {
      let sum = 0;
      this.transactions.forEach(item => {
        sum += item.amount * item.unit_price;
      })
      return sum;
    },
    isValidSelectOverheadDisableWaybilleId(){
      if(Number(this.form.overhead_invoice)===1) return false;
      return true;
    },
    isValidWaybillSelect(){
      if(Number(this.form.replace_the_waybill)===1 || Number(this.form.overhead_invoice)===1) return false;
      return true;
    },
    withholdingAmount(){
      let withholdingAmount=0;
      if (this.calculated_vat && this.form.withholding_share && this.form.withholding_denominator) {
        withholdingAmount = this.calculated_vat * this.form.withholding_share / this.form.withholding_denominator;
      }
      return Number(withholdingAmount);
    },
    isValidToContinue() {
      if (Number(this.form.invoice_type_id) === 0) return false;
      if (Number(this.form.invoice_category_id) === 0) return false;
      if (Number(this.form.invoice_type_id) === 0) return false;
      if (Number(this.form.supplier_company_id) === 0) return false;
      if (this.form.image === null) return false;
      if (!this.form.invoice_no) return false;
      return true;
    },
    isValidToSubmit() {
      if (!this.isValidToContinue) return false;
      if (Number(this.form.exchange_rate) === 0) return false;
      if (Number(this.form.invoice_receiving_type_id) === 0) return false;
      if (Number(this.form.currency_unit_id) === 0) return false;

      return true;
    },
    accountTheInvoiceTotal() {
      let total;
      return total = this.invoiceUnitAmount * this.invoiceAmount;
    },
    isValidToShowPopUp() {
      if (!this.transactions.length) return false;
      let returnValue = true;
      this.transactions.forEach(item => {
        if (!item.line_code) {
          returnValue = false;
          return false;
        }
        if (!item.line_code_name) {
          returnValue = false;
          return false;
        }
        if (!item.amount) {
          returnValue = false;
          return false;
        }
        if (!item.amount_unit_id) {
          returnValue = false;
          return false;
        }
        if (!item.unit_price) {
          returnValue = false;
          return false;
        }
      });
      if (!returnValue) return false;

      return true;
    },
  },
  data() {
    return {
      id: null,
      is_submitting: false,
      selecteMoreOption: false,
      didClickCancel: false,
      didClickSaved: false,
      invoiceAmount: null,
      invoiceUnitAmount: null,
      invoiceTitle: 'fabric title',
      more_amount_id: null,
      moreAmountOptions: {
        '1': 'Satış Faturası kes',
        '2': 'İade Faturası kes',
        '3': 'Yansıtma Faturası kes',
      },
      today: moment(),
      currentHoveredItem: null,
      waybills: [],
      unitOptions: [],
      instructionTypeOptionsRaw: [],
      unitOptionsRaw: [],
      vatDeducts: [],
      vatExceptions: [],
      vatOptions: [],
      currencyRates: [],
      companyOptionsRaw: [],
      options: {
        instructionTypeOptions: [],
        waybillableOptions: [],
        companyOptions: [],
        entryTypeOptions: {
          '0': this.$t('invoice.enter_manually'),
          '1': this.$t('invoice.scan_qr_code')
        },
        invoiceTypeOptions: [],
        invoiceCategoryOptions: [],
        vatOptions: [],
        vatOptionsRaw: [],
        currencyOptions: [],
        receiveTypeOptions: {},
        waybills: [],
        unitOptions: [],
        vatDeducts: [],
        vatExceptions: []
      },

      transactions: [],

      transaction: {
        vat_rate_id: null,
        waybillable_id: null,
        waybillable_type: null,
        instructable_id: null,
        instructable_type: null,
        order_product_id: null,
        order_product_id_2: null,
        order_size_and_color_id: null,
        instruction_type_id: null,
        line_code: null,
        line_code_name: null,
        amount: null,
        amount_unit_id: null,
        unit_price: null,
        unit_price_converted: null,
        vat_price: null,
        vat_price_converted: null,
        exchange_rate: null,
        vat_rate: null,
        price: null,
        price_converted: null,
        total_price: null,
        total_price_converted: null,
        description: null,
        withholding_amount: 5,
        withholding_share: 10,
        waybillable_ids: [],
        orderOptions: [],
        waybillable: null,
        instructable: null,
      },
      instruction_type_id: null,
      form: {
        is_withholding: '0',
        withholding_share: null,
        withholding_denominator: null,
        replace_the_waybill: 0,
        overhead_invoice: 0,
        file_names: ['image'],
        supplier_company_id: null,
        invoice_type_id: null,
        invoice_category_id: null,
        invoice_no: null,
        image: null,
        invoice_date: moment().format(LARAVEL_DATE_FORMAT),
        waybill_ids: [],
        currency_unit_id: null,
        invoice_receiving_type_id: null,
        exchange_rate: null,
        total_amount_including_taxes: null,
        amount_to_be_paid: null,
        total_discount: null,
        calculated_vat: null,
        total_amount: null,
        withholding_amount: null,
        withholding_amount_calculated_vat: null,
        vat_deduct_id: null,
        vat_exception_id: null,
        vat_deduct_description: "",
        vat_exception_description: "",
      },

      items: {data: []},
      fields: [
        {
          key: 'is_selected',
          label: this.$t('general.select'),
          sortable: false,
          class: 'text-center text-center border border-top-0 border-left-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'company_name',
          label: this.$t('invoice.company_name'),
          sortable: false,
          class: 'text-center border border-top-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'order_number',
          label: this.$t('invoice.order_number'),
          sortable: false,
          class: 'text-center border border-top-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'qr_code_no',
          label: this.$t('invoice.qr_code_no'),
          sortable: true,
          class: 'text-center border border-top-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'waybill_date',
          label: this.$t('invoice.waybill_date'),
          sortable: true,
          class: 'text-center border border-top-0 max-width-125px font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'invoice_number',
          label: this.$t('invoice.invoice_number'),
          sortable: true,
          class: 'text-center border border-top-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'invoice_type',
          label: this.$t('invoice.invoice_type'),
          sortable: true,
          class: 'text-center border border-top-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'invoice_kind',
          label: this.$t('invoice.invoice_kind'),
          sortable: true,
          class: 'text-center border border-top-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'color',
          label: this.$t('invoice.color'),
          sortable: true,
          class: 'text-center border border-top-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'size',
          label: this.$t('invoice.size'),
          sortable: true,
          class: 'text-center border border-top-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'waybill_quantity',
          label: this.$t('invoice.quantity'),
          sortable: true,
          class: 'text-center border border-top-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'normal_image',
          label: this.$t('invoice.document'),
          sortable: true,
          class: 'text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
      ],
    };
  },
  methods: {
    ...mapMutations({}),
    addNewRow() {
      let transaction = _.cloneDeep(this.transaction);
      this.transactions.push(transaction);
      if(Number(this.form.overhead_invoice)==1){
        let self = this;
        this.transactions.forEach((item,index) =>{
          self.unitOptions[index] = self.convertArrayToObjectByKey(self.unitOptionsRaw, 'id', 'code');
        })
      }
    },
    deleteTranscationsRow(index){
      this.transactions.splice(index,1);
    },
    getWaybills() {
      let self = this;
      this.$store.dispatch(GET_ITEMS, {
        url: 'api/waybills',
        filters: {
          invoiceable: true,
          transaction_type: 1,
          supplier_company_id: this.form.supplier_company_id
        }
      }).then(response => {
        if (response.status) {
          self.waybills = response.data.data;
          self.options.waybills = self.convertArrayToObjectByKey(response.data.data, 'id', 'document_number');
        }
      });
    },
    instructionTypeSave(event, key) {

      if (event.newValue[0]) {
        let self = this;
        this.transactions[key].waybillable = null;
        this.transactions[key].order_product_id = null;
        this.transactions[key].waybillable_ids = [];
        let selectedInstructionType = this.instructionTypeOptionsRaw.find(item => +item.id === +event.newValue[0]);

        this.transactions[key].line_code = selectedInstructionType.instructionable.code;
        this.transactions[key].line_code_name = selectedInstructionType.instructionable.logo_name;

        this.transactions[key].selectedInstructionTypeOption = selectedInstructionType;
        let instructionLogoTypeUnits = selectedInstructionType.units;
        let units = [];
        instructionLogoTypeUnits.forEach(item => {
          let unitItem = self.unitOptionsRaw.find(subItem => subItem.logo_name === item.logo_name);

          if (unitItem) {
            units.push({
              id: unitItem.id,
              name: unitItem.logo_name
            })
          }
        })
        if(Number(this.form.overhead_invoice)!==1){
          this.unitOptions[key] = self.convertArrayToObjectByKey(units, 'id', 'name');
        }
        this.getWaybillOrderOptions(event.newValue[0], key);
      }
    },
    waybillableIdsChanged(event, key) {
      if (event.newValue[0]) {
        this.transactions[key].waybillable = null;
        this.transactions[key].order_product_id = null;
        this.transactions[key].selectedOrderOption = this.transactions[key].orderOptions[event.newValue[0]];
        this.transactions[key].waybillable = this.transactions[key].orderOptions[event.newValue[0]];
      }
    },
    onSelectProduct(event, key) {
      let selectedItem = this.transactions[key].selectedOrderOption;
      let selectedItemDetail = selectedItem.detail[event.newValue[0]];
      this.transactions[key].waybillable_id = selectedItem.waybillable_id;
      this.transactions[key].waybillable_type = selectedItem.waybillable_type;
      this.transactions[key].instructable_id = selectedItemDetail.instructable_id
      this.transactions[key].instructable_type = selectedItemDetail.instructable_type;
      this.transactions[key].order_product_id = selectedItemDetail.order_product_id;
      this.transactions[key].order_product_id_2 = selectedItemDetail.order_product_id;
      this.transactions[key].amount_unit_id = selectedItemDetail.amount_unit_id;
      this.transactions[key].line_code = selectedItemDetail.color;
      this.transactions[key].line_code_name = selectedItemDetail.color;
      this.transactions[key].amount = selectedItemDetail.net_amount;
      this.transactions[key].unit_price = Number(selectedItemDetail.price);
      this.transactions[key].order_size_and_color_id = selectedItemDetail.order_size_and_color_id;
    },
    getWaybillOrderOptions(instruction_type_id = null, orderIndex) {
      let instructionTypeId = instruction_type_id ? instruction_type_id : this.instruction_type_id;
      if (this.form.supplier_company_id != null && instructionTypeId != null) {
        this.$store.dispatch(GET_ITEMS, {
          url: 'api/waybill/waybill-order-option',
          filters: {
            is_invoice: 1,
            instruction_type_id: instructionTypeId,
            supplier_company_id: this.form.supplier_company_id
          },
          acceptPromise: true,
        }).then((result) => {
          let self = this;
          if (result.status) {
            self.transactions[orderIndex].orderOptions = result.data;
          }
        });
      }
    },
    openPopUpModal() {
      this.$modal.show('invoice-confirm-modal');
      this.didClickCancel = false;
    },
    submitInvoice() {
      if (!this.isUserGranted('Invoice', ['update', 'create'])) {
        return false;
      }


      if (this.is_submitting) return;
      this.is_submitting = true;
      let invoiceTransactions = this.transactions;
      let form = new FormData();
      let self = this;
      let transactions = [];
      let exchangeRate = this.form.exchange_rate;

      invoiceTransactions.forEach((transaction, index) => {
        let tmpItem = {
          waybillable_id: transaction.waybillable_id || null,
          waybillable_type: transaction.waybillable_type || null,
          instructable_id: transaction.instructable_id || null,
          instructable_type: transaction.instructable_type || null,
          order_product_id: transaction.order_product_id_2 || null,
          order_size_and_color_id: transaction.order_size_and_color_id || null,
          instruction_type_id: transaction.instruction_type_id || null,
          line_code: transaction.line_code,
          line_code_name: transaction.line_code_name,
          vat_rate_id: transaction.vat_rate_id,
          amount: transaction.amount,
          amount_unit_id: transaction.amount_unit_id,
          unit_price: transaction.unit_price,
          unit_price_converted: transaction.unit_price * exchangeRate,
          vat_rate: transaction.vat_rate,
          vat_price: transaction.vat_price,
          vat_price_converted: transaction.vat_price * exchangeRate,
          exchange_rate: exchangeRate,
          price: transaction.price,
          price_converted: transaction.price * exchangeRate,
          total_price: transaction.total_price,
          total_price_converted: transaction.total_price * exchangeRate,
          description: transaction.description,
        };

        if (transaction.id) {
          tmpItem.id = transaction.id;
        }
        transactions.push(tmpItem);
      })

      this.form.waybills = [];
      this.form.waybills = this.form.waybill_ids.map(id => {
        return {id: id};
      });



      let tempForm = {
        ...this.form,
        is_service: 0,
        calculated_vat: this.calculated_vat,
        calculated_vat_converted: this.calculated_vat * exchangeRate,
        total_amount_including_taxes: this.total_amount,
        total_amount_including_taxes_converted: this.total_amount * exchangeRate,
        total_amount: this.total,       //tevkifat miktarını toplamdan miktardan çıkarıyoruz
        total_amount_converted: (this.total * exchangeRate),       //tevkifat miktarını toplamdan miktardan çıkarıyoruz
        amount_to_be_paid: this.total_amount - this.withholdingAmount,
        amount_to_be_paid_converted: (this.total_amount * exchangeRate) - (this.withholdingAmount*exchangeRate),
        total_discount: this.form.total_discount,
        total_discount_converted: this.form.total_discount  * exchangeRate,
        withholding_amount: this.withholdingAmount,
        transactions: transactions,
        waybills: this.form.waybill_ids
      };
      tempForm.invoice_date = moment(tempForm.invoice_date).format('YYYY-MM-DD');
      

      this.createFormDataForList(form, null, tempForm);
      this.$store.dispatch(CREATE_ITEM, {
        url: `api/invoice`,
        contents: form,
        showLoading: true,
      }).then(response => {
        if (response.status) {
          self.sweetAlertClose();
          self.is_submitting = false;
          setTimeout(function () {
            self.sweetAlertSuccess(self.$t('invoice.invoice_success_message')).then(() => {
              self.$router.push({name: 'invoice.index'});
            })
          }, 500);
        } else {
          let result = response.data.response;
          if (result.hasOwnProperty('data') && result.data.hasOwnProperty('message')) {
            self.sweetAlertError(result.data.message);
          } else {
            self.sweetAlertError(result.data);
          }
        }
        self.is_submitting = false;
      })
    },
    onRowClicked(item, index, event) {
      if (this.form.waybill_ids.includes(item.id)) {
        this.form.waybill_ids.splice(this.form.waybill_ids.indexOf(item.id), 1);
      } else {
        this.form.waybill_ids.push(item.id);
      }
    },
    mouseLeave(e) {
      this.currentHoveredItem = null;
    },
    mouseMove(e) {
      if (this.currentHoveredItem === null) return;
      document.getElementById('detail-pop-up').style.top = (e.pageY + 20) + 'px';
      document.getElementById('detail-pop-up').style.left = (e.pageX - (175 / 2)) + 'px';
    },
    rowHovered(item) {
      this.currentHoveredItem = item;
    },
    onInvoiceUpload(payload) {
      document.getElementById('invoice_image').src = payload.blob;
    },
  },
  created() {
    this.$store.commit(RESET_VALUES);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t('general.dashboard'), route: "dashboard"},
      {title: this.$t('invoice.invoices'), route: "invoice.index"},
      {title: this.$t('invoice.edit')}
    ]);

    if (!this.isUserGranted('Invoice', ['update', 'create'])) {
      return false;
    }

    let self = this, promises = [];
    promises.push(this.$store.dispatch(GET_ITEMS, {url: `api/supplier-companies`}));
    promises.push(this.$store.dispatch(GET_ITEMS, {url: `api/currency`}));
    promises.push(this.$store.dispatch(GET_ITEMS, {url: `api/invoice-type`}));
    promises.push(this.$store.dispatch(GET_ITEMS, {url: `api/invoice-category`, filters: {display_in: 1}}));/* display_in: 1 Fatura kabul sayfasına işaret eder */
    promises.push(this.$store.dispatch(GET_ITEMS, {url: `api/invoice-receive-type`}));
    promises.push(this.$store.dispatch(GET_ITEMS, {url: `api/instruction-type`}));
    promises.push(this.$store.dispatch(GET_ITEMS, {
      url: 'api/vat-rates', filters: {}
    }));

    promises.push(this.$store.dispatch(GET_ITEMS, {url: 'api/units'}));

    promises.push(this.$store.dispatch(GET_ITEMS, {url: 'api/currency-rate'}));

    promises.push(this.$store.dispatch(GET_ITEMS, {url: 'api/vat-deducts'}));

    promises.push(this.$store.dispatch(GET_ITEMS, {url: 'api/vat-exceptions'}));

    if (this.$route.params.id != null) {
      promises.push(this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, {url: `api/invoice/` + this.$route.params.id}));
    }

    Promise.all(promises).then(responses => {
      if (responses[0].status) {
        self.companyOptionsRaw = responses[0].data;
        self.options.companyOptions = self.convertArrayToObjectByKey(responses[0].data, 'id', 'name_for_accounting');
      }
      if (responses[1].status) {
        self.options.currencyOptions = self.convertArrayToObjectByKey(responses[1].data, 'id', 'code');
      }
      if (responses[2].status) {
        self.options.invoiceTypeOptions = self.convertArrayToObjectByKey(responses[2].data, 'id', 'translations.0.name');
      }
      if (responses[3].status) {
        self.options.invoiceCategoryOptions = self.convertArrayToObjectByKey(responses[3].data, 'id', 'translations.0.name');
      }
      if (responses[4].status) {
        self.options.receiveTypeOptions = self.convertArrayToObjectByKey(responses[4].data, 'id', 'translations.0.name');
      }
      if (responses[5].status) {
        self.options.instructionTypeOptions = self.convertArrayToObjectByKeyList(responses[5].data, 'id', ['translations.0.name','instructionable.code'])
        self.instructionTypeOptionsRaw = responses[5].data;
      }
      if (responses[6].status) {
        self.options.vatOptionsRaw = responses[6].data;
        self.options.vatOptions = self.convertArrayToObjectByKey(responses[6].data, 'id', 'vat.rate');
      }

      if (responses[7].status) {
        self.options.unitOptions = self.convertArrayToObjectByKey(responses[7].data, 'id', 'translations.0.name');
        self.unitOptionsRaw = responses[7].data;
      }

      if (responses[8].status) {
        self.currencyRates = responses[8].data.data;
      }

      if (responses[9].status) {
        self.vatDeducts = responses[9].data;
        self.options.vatDeducts = self.convertArrayToObjectByKey(responses[9].data, 'id', 'code');
      }
      if (responses[10].status) {
        self.vatExceptions = responses[10].data;
        self.options.vatExceptions = self.convertArrayToObjectByKey(responses[10].data, 'id', 'code');
      }

      if (this.$route.params.id != null) {
        if (responses[11].status) {
          let responseData = responses[11].data;
          self.id = responseData.id;
          self.today = moment(responseData.created_at, LARAVEL_DATE_FORMAT);
          self.form.invoice_date = moment(responseData.invoice_date, LARAVEL_DATE_FORMAT);
          self.form.image = responseData.image_link;
          self.form.supplier_company_id = responseData.supplier_company_id;
          self.form.invoice_no = responseData.invoice_no;
          self.form.invoice_type_id = responseData.invoice_type_id;
          self.form.invoice_category_id = responseData.invoice_category_id;
          self.form.invoice_category_id = responseData.invoice_category_id;
        }
      }
    })
    setTimeout(this.onHitBottom, 500);
  },
  watch: {
    transactions: {
      handler: function (value, oldValue) {
        if (value && value.length) {
          value.map(item => {
            let rate = this.options.vatOptions[item.vat_rate_id];
            item.price = item.amount * item.unit_price;
            item.vat_price = item.price * rate / 100;
            item.total_price = item.price + item.vat_price;
            item.vat_rate = rate;

            return item;
          })
        }
      },
      deep: true
    },
    'form': {
      handler: function (newValue, oldValue) {
        let self = this;
        let totalAmount = 0;
        let totalVat = 0;

        this.transactions.forEach((item, index) => {
          let itemPrice = item.amount * item.unit_price;
          totalAmount += itemPrice;
          totalVat += itemPrice * self.options.vatOptions[item.vat_rate_id] / 100;
        });

        newValue.total_amount = totalAmount;
        newValue.total_amount_including_taxes = totalAmount + totalVat;
        newValue.amount_to_be_paid = totalAmount + totalVat - newValue.total_discount;
        newValue.calculated_vat = totalVat;

        if (newValue && newValue.currency_unit_id !== 160) {
          let exchangeRate = newValue.exchange_rate || 1;
          newValue.calculated_vat_converted = newValue.calculated_vat * exchangeRate;
          newValue.total_amount_converted = newValue.total_amount * exchangeRate;
          newValue.total_discount_converted = newValue.total_discount * exchangeRate;
          newValue.amount_to_be_paid_converted = newValue.amount_to_be_paid * exchangeRate;
          newValue.total_amount_including_taxes_converted = newValue.total_amount_including_taxes * exchangeRate;
        }
        return newValue;
      },
      deep: true
    },
    'form.vat_deduct_id'(value) {
      let item = this.vatDeducts.find(item => +item.id === +value);
      this.form.vat_deduct_description = item.name;
    },
    'form.waybill_ids'(newValue) {
      if (newValue) {
        let self = this;
        this.transactions = [];
        newValue.forEach(item => {
          let waybill = this.waybills.find(waybillItem => +waybillItem.id === +item);
          waybill.transactions.forEach((transactionItem, index) => {
            let tmpItem = {
              waybill_id: waybill.id,
              vat_rate_id: null,
              waybillable_id: transactionItem.waybillable_id,
              waybillable_type: transactionItem.waybillable_type,
              instructable_id: transactionItem.instructable_id,
              instructable_type: transactionItem.instructable_type,
              order_product_id: transactionItem.instruction_type_id,
              order_product_id_2: transactionItem.order_product_id,
              line_code: transactionItem.product_name,
              instruction_type_id: transactionItem.instruction_type_id,
              line_code_name: transactionItem.product_name,
              amount: transactionItem.net_amount,
              amount_unit_id: transactionItem.unit_id,
              unit_price: transactionItem.instructable.price,
              vat_price: null,
              price: null,
              total_price: null,
              description: null,
              waybillable: transactionItem.waybillable,
              instructable: transactionItem.instructable,
            }

            tmpItem.waybillable = null;
            tmpItem.order_product_id = null;
            tmpItem.waybillable_ids = [];
            tmpItem.selectedInstructionTypeOption = this.instructionTypeOptionsRaw.find(item => +item.id === +tmpItem.instruction_type_id);
            self.unitOptions[index] = self.convertArrayToObjectByKey(self.unitOptionsRaw, 'id', 'code');

            this.$store.dispatch(GET_ITEMS, {
              url: 'api/waybill/waybill-order-option',
              filters: {
                is_invoice: 1,
                instruction_type_id: transactionItem.instruction_type_id,
                supplier_company_id: this.form.supplier_company_id
              },
              acceptPromise: true,
            }).then((result) => {
              if (result.status) {
                tmpItem.orderOptions = result.data;
                let prefix;
                if (tmpItem.waybillable_type == "App\\Models\\Sample") {
                  prefix = "S-";
                } else if (tmpItem.waybillable_type == "App\\Models\\Order") {
                  prefix = "O-";
                } else {
                  this.sweetAlertError(this.$t('general.cause_error_please_try_again'))
                }

                let tmpId = prefix + tmpItem.waybillable_id.toString();

                tmpItem.waybillable_ids = [tmpId.toString()];
                tmpItem.waybillable = null;
                tmpItem.order_product_id = null;
                tmpItem.selectedOrderOption = tmpItem.orderOptions[tmpId.toString()];
                tmpItem.waybillable = tmpItem.orderOptions[tmpId.toString()];
                let selectedItem = tmpItem.selectedOrderOption;
                let selectedItemDetail = selectedItem.detail[tmpItem.instructable_id];

                tmpItem.unit_price = selectedItemDetail.price;
                tmpItem.order_product_id = selectedItemDetail.instructable_id;
              }
            });

            this.transactions.push(tmpItem);
          })
        });
      }
    },
    more_amount_id(newValue, oldValue) {
      if (newValue != null)
        this.selecteMoreOption = true;
    },
    'form.invoice_category_id'(value) {
      /*      let self = this;
            if (value == 1) {
              let tmpData = self.instructionTypeOptions.filter(item => item.type == 1 || item.type == 3);
              self.options.instructionTypeOptions = self.convertArrayToObjectByKey(tmpData, 'id', 'translations.0.name');
            }

            if (value == 2) {
              let tmpData = self.instructionTypeOptions.filter(item => item.type == 2);
              self.options.instructionTypeOptions = self.convertArrayToObjectByKey(tmpData, 'id', 'translations.0.name');
            }

            this.currentHoveredItem = null;
            this.form.waybill_ids = [];
            this.onHitBottom(null);*/
    },
    'form.supplier_company_id'(newValue) {
      if (+newValue !== 0) {
        this.transactions = [];
        this.form.waybill_ids = [];
        let selectedCompany = this.companyOptionsRaw.find(item => item.id === +newValue);
        this.form.currency_unit_id = selectedCompany.currency_unit_id;
        this.getWaybills();
      }
    },
    'form.overhead_invoice'(newValue){
      let self = this;
      if(Number(newValue)==1){
        self.transactions.forEach((item,index) =>{
          self.unitOptions[index] = self.convertArrayToObjectByKey(self.unitOptionsRaw, 'id', 'code');
        })
        let tmpData =this.instructionTypeOptionsRaw.filter(item => item.instructionable.expense_category == 2);
        self.options.instructionTypeOptions = self.convertArrayToObjectByKey(tmpData, 'id', 'translations.0.name');
      }else{
          self.unitOptions=[]
          let tmpData = this.instructionTypeOptionsRaw.filter(item => item.instructionable.expense_category == 1);
          self.options.instructionTypeOptions = self.convertArrayToObjectByKey(tmpData, 'id', 'translations.0.name');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.h-50vh {
  height: 50vh !important;
}
</style>
